import React from "react"
import { graphql } from "gatsby"
import Layout from "components/Layout"
import SEO from "components/SEO"

const NotFoundPage = ({
  data: {
    prismic: { site_settings, images, description, },
  },
}) => (
  <Layout site_settings={site_settings}>
    <SEO
      slug="/404"
      title={`404 Not Found`}
      description={description}
      images={images}
    />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage

export const query = graphql`
  query ErrorPage {
    prismic {
      site_settings(lang: "da-dk", uid: "site-settings") {
        ...Footer
        images: default_meta_image
        description: default_meta_description
      }
    }
  }
`
